<template>
  <div class="card-wrapper">
    <router-link :to="{ name: 'Home' }">
      <img src="../../assets/images/logo.png" alt="Reduct logo" />
    </router-link>

    <div class="card">
      <div class="card-header">
        <div class="card-header-title">{{ $t("login") }}</div>
      </div>

      <div class="card-content">
        <b-message v-if="$route.params.expired" type="is-danger">
          {{ $t("login_expired") }}
        </b-message>
        <form @submit.prevent="submit">
          <CustomInput
            v-model="form.emailAddress"
            :label="$t('email')"
            :placeholder="$t('email')"
            :horizontal="false"
            :type="'email'"
            :icon="'email'"
            :invalid="failed"
          ></CustomInput>

          <CustomInput
            v-model="form.password"
            :label="$t('password')"
            :placeholder="$t('password')"
            :horizontal="false"
            :type="'password'"
            :icon="'lock'"
            :invalid="failed"
            :invalid-message="$t('login_failed')"
          ></CustomInput>

          <b-button
            expanded
            type="is-primary"
            native-type="submit"
            class="mt-5"
            :loading="loading"
          >
            {{ $t("login") }}
          </b-button>

          <b-button
            expanded
            type="is-text"
            class="mt-2"
            tag="router-link"
            :to="{ name: 'ForgotPassword' }"
          >
            {{ $t("password_forgot") }}
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CustomInput from "../../components/forms/CustomInput";

export default {
  name: "Login",
  components: { CustomInput },
  data() {
    return {
      loading: false,
      failed: false,
      form: {
        emailAddress: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (process.env.VUE_APP_USER_EMAIL && process.env.VUE_APP_USER_PASSWORD) {
      this.form.emailAddress = process.env.VUE_APP_USER_EMAIL;
      this.form.password = process.env.VUE_APP_USER_PASSWORD;
    }

    if (this.user) {
      this.$router.push({ name: "SelectEntity" });
    }
  },
  methods: {
    ...mapActions(["login", "selectEntity"]),
    submit() {
      this.loading = true;

      this.login(this.form)
        .then(() => {
          this.$router.push({ name: "SelectEntity" });
        })
        .catch(() => {
          this.failed = true;
          this.loading = false;
        });
    },
  },
};
</script>
